import React, { useEffect } from "react";
import { ethers } from "ethers";
const privatesaledetails = async (props) => {
  try {
    //abi I am using in my code
    const ERC20_ABI = [
      // Read-Only Functions
      "function decimals() view returns (uint8)",
      "function symbol() view returns (string)",
      "function name() view returns (string)",
      "function totalSupply() view returns (uint256)",
      "function getStatus() view returns (uint256)",
      "function owner() view returns (address)",
      "function claimedPercent() view returns (uint256)",
      "function getCaps() view returns (uint256, uint256)",
      "function getLimits() view returns (uint256, uint256)",
      "function getTimes() view returns (uint256, uint256)",
      "function getFirstReleasePercent() view returns (uint256)",
      "function getCycleReleasePercent() view returns (uint256)",
      "function getCycleReleaseDuration() view returns (uint256)",
      "function getSaleName() view returns (string)",
      "function getWhitelist() view returns (bool)",
      "function getTotalDepositAmount() view returns (uint256)",
      "function getUserDepositAmount(address) view returns (uint256)",
      "function getAllWhitelisters() view returns (address [])",
      "function getAllContributors() view returns (address [])",
    ];
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    let accounts = await provider.send("eth_requestAccounts", []);
    let account = accounts[0];
    const network = await provider.getNetwork();
    if (!account) {
      return;
    }
    const contract = new ethers.Contract(
      props.contract_address,
      ERC20_ABI,
      provider
    );

    const private_sale_details = {};

    // get Contract Name
    const presale_currency_details = network;
    private_sale_details.presale_currency_details = presale_currency_details;
    private_sale_details.presale_currency = presale_currency_details?.name;

    // get Contract Name
    const contract_name = await contract.getSaleName();
    private_sale_details.contract_name = contract_name;

    // get Contract Name
    const contract_owner = await contract.owner();
    private_sale_details.contract_owner = contract_owner;

    // get Contract Name
    const contract_status = await contract.getStatus();
    const bigNumber = ethers.BigNumber.from(contract_status);
    const integer = bigNumber.toNumber();
    private_sale_details.contract_status = integer;

    // get User deposit amount
    const get_user_deposit_amount = await contract.getUserDepositAmount(
      account
    );

    private_sale_details.get_user_deposit_amount = ethers.utils.formatEther(
      parseInt(get_user_deposit_amount._hex).toString()
    );

    // get claimed percent
    const get_claimed_percent = await contract.claimedPercent();

    private_sale_details.claimed_percent = getTimeFormat(
      get_claimed_percent._hex
    );

    // get Total inveted amount
    const get_total_invested_amount = await contract.getTotalDepositAmount();
    private_sale_details.get_total_invested_amount = ethers.utils.formatEther(
      parseInt(get_total_invested_amount._hex).toString()
    );

    // get whitelist
    const contract_whitelist = await contract.getWhitelist();
    private_sale_details.contract_whitelist = contract_whitelist;

    // get whitelist
    const contract_AllWhitelist = await contract.getAllWhitelisters();
    private_sale_details.contract_whitelist_array = contract_AllWhitelist;

    // get AllContributors
    const contract_getAllContributors = await contract.getAllContributors();
    private_sale_details.contract_contributors_list =
      contract_getAllContributors;

    // get Softcap and Hard cap
    const contract_caps = await contract.getCaps();
    const softCap = ethers.utils.formatEther(contract_caps[0]._hex);
    const hardCap = ethers.utils.formatEther(contract_caps[1]._hex);
    private_sale_details.contract_softCap = softCap;
    private_sale_details.contract_hardCap = hardCap;

    // get Max and Min buy
    const contract_min_max_buy = await contract.getLimits();
    const min_buy = ethers.utils.formatEther(contract_min_max_buy[0]._hex);
    const max_buy = ethers.utils.formatEther(contract_min_max_buy[1]._hex);
    private_sale_details.min_buy = min_buy;
    private_sale_details.max_buy = max_buy;

    // get start and end time
    const contract_start_end_time = await contract.getTimes();

    private_sale_details.start_time = getTimeFormat(
      contract_start_end_time[0]._hex
    );
    private_sale_details.end_time = getTimeFormat(
      contract_start_end_time[1]._hex
    );

    // getFirstReleasePercent
    const contract_first_release = await contract.getFirstReleasePercent();
    private_sale_details.contract_first_release = getTimeFormat(
      contract_first_release._hex
    );

    // getCycleReleasePercent
    const contract_cycle_release = await contract.getCycleReleasePercent();
    private_sale_details.contract_cycle_release = getTimeFormat(
      contract_cycle_release._hex
    );
   

    // getCycleReleaseDuration
    const contract_cycle_duration = await contract.getCycleReleaseDuration();
    private_sale_details.contract_cycle_duration = getTimeFormat(
      contract_cycle_duration._hex
    );

    console.log('contract_cycle_release is: ', private_sale_details)
    return private_sale_details;
  } catch (error) {
    console.log('error is11111111111: ', error)
  }
};
const getTimeFormat = (time) => {
  const timeInSeconds = parseInt(time, 16);
  return timeInSeconds;
};
const getTotalContributorsList = async (props) => {
  try {
    //abi I am using in my code
    const ERC20_ABI = [
      // Read-Only Functions
      "function getUserDepositAmount(address) view returns (uint256)",
    ];
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const contract = new ethers.Contract(
      props.contract_address,
      ERC20_ABI,
      provider
    );

    const private_sale_details = {};

    // get User deposit amount
    const get_user_deposit_amount = await contract.getUserDepositAmount(
      props.wallet_address
    );
    private_sale_details.get_user_deposit_amount = ethers.utils.formatEther(
      parseInt(get_user_deposit_amount._hex).toString()
    );


    return private_sale_details;
  } catch (error) {
  }
};
export { privatesaledetails, getTotalContributorsList };
