import React, { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import moment from "moment";
//IMPORT SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import axios from "axios";
import {
  _parseFloat,
  addDotsMiddleOfString,
  formatDate,
  timeStampToUtcDateObj,
  getTokenProgressPercentage,
  isMainnetSelected,
} from "utils/helpers";

//IMPORT COMPONENT
import Cart from "componants/dashboard/Cart";
import CartTokenPage from "componants/createtokenpage/Cart";
import {
  getTotalContributorsList,
  launchpadBlockchainDetails,
} from "componants/launchpad/LaunchpadBlockchainDetails";

import TrendingSlider from "componants/dashboard/TrendingSlider";
import AirdropCart from "componants/dashboard/AirdropCart";
import Loader from "componants/common/Loader";
import { STATUSES } from "../utils/statuses";
//REDUX STRORE
import { useSelector, useDispatch } from "react-redux";
import DashboardThunkAPI from "store/features/dashboard/middleware";
import LaunchPadThunkAPI from "store/features/launchPads/middleware";
import privateSaleThunkAPI from "store/features/privateSale/middleware";
import tokenThunkAPI from "store/features/token/middleware";
import {
  likeDislikeLaunchpad,
  likeDislikePresale,
  changeTokenCartNetwork,
  likeDislikeTokenpage,
} from "store/features/dashboard/dashboardSlice";
//AUTH
import { getAuthToken } from "services/authToken.service";
import NoDataFoundSmall from "componants/common/NoDataFoundSmall";
import { privatesaledetails } from "../utils/contractUtils/privateSaleDetails";
import { database } from "firebase";
import { setUserWalletInfo } from "store/features/users/usersSlice";
import { ethers } from "ethers";
export default function Home() {
  const router = useRouter();
  const dispatch = useDispatch();

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userState = useSelector((state) => state.users);

  const {
    allLaunchpadList,
    allPresaleList,
    allTokenpageList,
    status,
    loadingAllLaunchpadList,
    loadingAllPrivateSaleList,
    loadingAllTokenPageList,
  } = useSelector((state) => state.dashboardState);

  const {
    singleTokenDetails,
    singleStatus,
    tokenPoolAddress,
    poolDetails,
    tokenPageFavouriteStatus,
    getTokenExplorerPoolList,
  } = useSelector((state) => state.tokenState);
  const [size] = useState(3);
  const [pageNo, setpageNo] = useState(1);
  const [privateSaleList, setPrivateSaleList] = useState([]);
  const [launchpadList, setLaunchpadList] = useState([]);
  const [isBlockchainLoading, setIsBlockchinLoading] = useState(true);
  const [isNetworkChange, setIsNetworkChange] = useState(false);
  const [marketPlaceData, setMarketPlaceData] = useState([]);
  const [launchpadBlockchainData, setLaunchpadBlockchainData] = useState([]);
  const [tokenPageData, setTokenPageData] = useState([]);
  const [progressPercentage, setProgressPercentage] = useState(null);

  useEffect(() => {
    if (allTokenpageList) {
      let tokenDetails = [];
      for (let i = 0; i < allTokenpageList?.length; i++) {
        tokenDetails.push({
          contractAddress:
            allTokenpageList[i]?.token_data?.[0]?.contract_address,
          chainId: allTokenpageList[i]?.token_data?.[0]?.networkName,
        });
      }
      if (tokenDetails) {
        setTokenPageData(tokenDetails);

        if (tokenPageData.length == 0) {
          dispatch(
            tokenThunkAPI.getTokenExplorerPoolListAsync({
              tokenDetails,
              callback: (res) => {},
            })
          );
        }
      }
    }
  }, [allTokenpageList]);

  useEffect(() => {
    if (getTokenExplorerPoolList) {
      const mergedArray = allTokenpageList.map((item1) => {
        let item2;
        let tokenMatchFound = false;
        // Iterate through token_data array in item1
        for (const token of item1.token_data) {
          item2 = getTokenExplorerPoolList.find((item) =>
            item?.token_name?.some((t) => t.name === token.name)
          );

          if (item2) {
            tokenMatchFound = true;
            break; // Exit loop if a match is found
          }
        }

        // If no match was found in getTokenExplorerPoolList, check item1.poolDetails[0].attributes.name
        if (!item2 && !tokenMatchFound) {
          if (item1.poolDetails) {
            for (const token of item1.poolDetails) {
              // item2 = token === token.attributes.name;
              item2 = getTokenExplorerPoolList.find((item) => {
                const inputString = token.attributes.name;
                const parts = inputString.split("/");
                const textBeforeSlash = parts[0].trim();

                return item?.token_name?.some(
                  (t) => t.name === textBeforeSlash
                );
              });
              if (item2) {
                break; // Exit loop if a match is found
              }
            }
          }
        }

        return item2 ? { ...item1, ...item2 } : item1;
      });

      setMarketPlaceData(mergedArray);
    }
  }, [getTokenExplorerPoolList]);

  const getPrivateSaleBlockchain = async () => {
    try {
      let privatesaleData = [...allPresaleList];

      for (let i = 0; i <= allPresaleList.length; i++) {
        const contract_address = {
          contract_address:
            allPresaleList[i].privateSaleContractDetails.returnValues[0],
        };
        let x1 = await privatesaledetails(contract_address);

        let x = JSON.parse(JSON.stringify(x1));
        x._id = allPresaleList[i]?._id;
        x.isLiked = allPresaleList[i]?.isLiked;
        x.logo_url = allPresaleList[i]?.logo_url;
        x.progresspercentage = getTokenProgressPercentage(
          x?.contract_hardCap,
          x?.get_total_invested_amount
        );
        x.presale_currency = allPresaleList[i]?.presale_currency;
        x["from_blockchain"] = true;
        privatesaleData[i] = x;
      }

      setPrivateSaleList(privatesaleData);
    } catch (e) {
      setIsBlockchinLoading(false);
    }
  };

  const handleAddRemoveLaunchpad = (Id) => {
    try {
      const payload = {
        launchpad_id: Id,
      };
      if (!getAuthToken()) {
        router.push("/?forLoginIn=true");
        return;
      }
      dispatch(likeDislikeLaunchpad(Id));

      dispatch(
        LaunchPadThunkAPI.addFavouriteLaunchpadAsync({
          payload: payload,
          callback: (res) => {
            if (res?.status) {
            }
          },
        })
      );
    } catch (error) {}
  };

  const handleAddRemoveFavouritePrivateSale = (Id) => {
    try {
      const payload = {
        presale_id: Id,
      };
      if (!getAuthToken()) {
        router.push("/?forLoginIn=true");
        return;
      }
      dispatch(likeDislikePresale(Id));

      let d = JSON.parse(JSON.stringify(privateSaleList));

      d.map((item, index) => {
        if (item?._id == Id) {
          item.isLiked = !item.isLiked;
        }
      });
      setPrivateSaleList(d);

      dispatch(
        privateSaleThunkAPI.addFavouritePresaleAsync({
          payload: payload,
          callback: (res) => {
            if (res.status) {
              const payload = {
                limitVal: size,
              };
            }
          },
        })
      );
    } catch (error) {}
  };

  // add or remove favourite launchpad to list
  const handleTokenpageFavUnfav = (Id, status) => {
    if (!getAuthToken()) {
      router.push("/?forLoginIn=true");
      return;
    }
    // dispatch(likeDislikeTokenpage(Id));

    let temp = [...marketPlaceData];
    temp.map((item, index) => {
      if (item?._id == Id) {
        item.isLiked = !item.isLiked;
      }
    });
    setMarketPlaceData(temp);

    try {
      const payload = {
        tokenPageid: Id,
        favouiteadded: !status,
      };
      dispatch(
        tokenThunkAPI.tokenPageAddRemoveFavouriteAsync({
          payload: payload,
          callback: (res) => {
            if (res?.status) {
            }
          },
        })
      );
    } catch (error) {}
  };
  const getLaunchPadList = (
    pageNo,
    size,
    searchQuery,
    filters,
    chain,
    timeZone
  ) => {
    try {
      const payload = {
        pageNo: pageNo,
        size: size,
        search: "",
        filter: filters,
        timeZone: timeZone,
        chainfilter: chain,
        isMainnet: isMainnetSelected(chain),
      };
      dispatch(DashboardThunkAPI.getAllLaunchpadAsync(payload));
    } catch (error) {
      // console.log('error is: ', error)
    }
  };

  const getPrivateSaleList = () => {
    setIsBlockchinLoading(true);
    const payload = {
      page: pageNo,
      size: size,
      // size,
      search: "",
      filter: "ALL",
      chainfilter: userState.userWalletInfo?.network?.chainId || "ALL",
      timeZone: timezone,
    };

    dispatch(
      DashboardThunkAPI.getAllPresaleAsync({
        payload: payload,
        cb: (res) => {
          setPrivateSaleList(res?.data);

          setIsBlockchinLoading(false);
        },
      })
    );
  };

  const getTokenPageList = () => {
    const payload = {
      pageNo: pageNo,
      size: size,
      sortBy: -1,

      // search: searchQuery,
    };
    dispatch(DashboardThunkAPI.getAllTokenPageAsync(payload));
  };

  // check If user switch network
  useEffect(() => {
    if (typeof window.ethereum !== "undefined") {
      const handleNetworkChange = (chainId) => {
        let ID = "";
        let name = "";
        switch (chainId) {
          case "0x1":
            ID = 1;
            name = "eth";
            break;
          case "0x61":
            ID = 97;
            name = "bnbt";
            break;
          case "0x38":
            ID = 56;
            name = "bnb";
            break;
          case "0x5":
            ID = 5;
            name = "goerli";
            break;

          default:
            ID = userState?.userWalletInfo?.network?.chainId;
            name = userState?.userWalletInfo?.network?.name;
            break;
        }

        let newUserWalletInfo = {
          ...userState.userWalletInfo,
          network: {
            ...userState?.userWalletInfo?.network,
            chainId: ID,
            name: name,
          },
        };
        dispatch(setUserWalletInfo(newUserWalletInfo));
        setIsNetworkChange(true);
        getLaunchPadList(pageNo, size, "", "ALL", ID, timezone);
      };

      window.ethereum.on("chainChanged", handleNetworkChange);

      return () => {
        window.ethereum.removeListener("chainChanged", handleNetworkChange);
      };
    }
  }, []);

  useEffect(() => {
    getPrivateSaleList();
    if (isNetworkChange == false) {
      getLaunchPadList(
        pageNo,
        size,
        "",
        "ALL",
        userState?.userWalletInfo?.network?.chainId,
        timezone
      );
    }
    getTokenPageList();
  }, [userState.userWalletInfo?.network?.chainId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (allLaunchpadList) {
          let progressArray = [];
          for (let i = 0; i < allLaunchpadList?.length; i++) {
            const result = await getTokenProgressPercentage(
              allLaunchpadList[i]?.hardcap,
              allLaunchpadList[i]?.get_total_invested_amount,
              allLaunchpadList[i]?.presale_addr
            );
            progressArray.push(result);
          }
          const newArray = allLaunchpadList.map((item, index) => {
            return { ...item, percentage: progressArray[index] };
          });
          setLaunchpadList(newArray);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [allLaunchpadList]);

  const onChangeTokenCartNetwork = (
    selectedTokenObj,
    selected_detail_index,
    tokenPageId,
    tokan_page_index
  ) => {
    const updatedArray = tokenPageData.map((person) => {
      if (person.contractAddress === selectedTokenObj?.contract_address) {
        return { ...person, chainId: selectedTokenObj?.networkName }; // Update age for matching ID
      }
      return person; // Keep other objects unchanged
    });

    dispatch(
      tokenThunkAPI.getTokenExplorerPoolListAsync({
        tokenDetails: updatedArray,
        callback: (res) => {},
      })
    );

    dispatch(
      changeTokenCartNetwork({
        selectedTokenObj: selectedTokenObj,
        selected_detail_index: selected_detail_index,
        tokenPageId: tokenPageId,
        tokan_page_index: tokan_page_index,
      })
    );
    // set
  };

  // if (
  //   (!loadingAllLaunchpadList &&
  //     !loadingAllPrivateSaleList &&
  //     !allPresaleList &&
  //     !isBlockchainLoading) ||
  //   ((!allPresaleList || allPresaleList?.length < 1) &&
  //     (!allLaunchpadList || allLaunchpadList?.length < 1))
  // ) {
  //   return (
  //     <div className='dashInnerBox'>
  //       <NoDataFoundSmall
  //         message='No data available'
  //         className='noRecordSection main-listing-data'
  //       />
  //     </div>
  //   );
  // }
  // return method
  return (
    <div>
      {/* {STATUSES?.LOADING == status && <Loader />} */}

      {(loadingAllLaunchpadList ||
        loadingAllPrivateSaleList ||
        isBlockchainLoading) && <Loader />}
      {/* <TrendingSlider /> */}

      <div className="dashInnerBox">
        <div className="latestLaunchpadHeading ">
          {allLaunchpadList && allLaunchpadList?.length > 0 && (
            <h2 className=" font20 fontBold">Latest Launchpads</h2>
          )}
          {allLaunchpadList && allLaunchpadList?.length == 3 && (
            <Link href="/launchpad" className="font16">
              View all
            </Link>
          )}
        </div>
        <div className="latestLaunchpadSection">
          <Swiper
            spaceBetween={20}
            slidesPerView={3}
            onSlideChange={() => {}}
            onSwiper={(swiper) => {}}
            breakpoints={{
              220: {
                slidesPerView: 1.2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2.2,
                spaceBetween: 20,
              },
              1050: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              1199: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {launchpadList &&
              Array.isArray(launchpadList) &&
              launchpadList.map((item, i) => (
                <SwiperSlide key={i + 1}>
                  <Cart
                    Id={item?._id}
                    tokenName={item?.tokenName}
                    token_symbol={item?.token_symbol}
                    imageurl={item?.logo_url}
                    status={item?.launchpad_status}
                    isKYC={item?.kyc}
                    isAudit={item?.audit}
                    isSAYF={item?.sayf}
                    start_date={item?.start_date}
                    end_date={item?.end_date}
                    created_date={item?.created_at}
                    currency={item?.currency}
                    isLiked={item?.isLiked}
                    handleLikeUnlike={handleAddRemoveLaunchpad}
                    detailPageLink={`/launchpad/${item?._id}`}
                    progresspercentage={item?.percentage}
                    softcap={item?.softcap}
                    hardcap={item?.hardcap}
                    get_total_invested_amount={item?.get_total_invested_amount}
                    selectedNetworkChainId={
                      userState?.userWalletInfo?.network?.chainId
                    }
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>

        {/* latest presales section start */}
        <div className="latestLaunchpadHeading">
          {allPresaleList && allPresaleList?.length > 0 && (
            <h2 className=" font20 fontBold">Latest Private Sale</h2>
          )}

          {allPresaleList && allPresaleList?.length >= 3 && (
            <Link href="/private-sale" className="font16">
              View all
            </Link>
          )}
        </div>
        <div className="latestLaunchpadSection presalesSection">
          <Swiper
            spaceBetween={20}
            slidesPerView={3}
            onSlideChange={() => {}}
            onSwiper={(swiper) => {}}
            breakpoints={{
              220: {
                slidesPerView: 1.2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2.2,
                spaceBetween: 20,
              },
              1050: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              1199: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {privateSaleList &&
              privateSaleList.length > 0 &&
              Array.isArray(privateSaleList) &&
              privateSaleList.slice(0, 3).map((item, i) => (
                <SwiperSlide key={i + 1}>
                  <Cart
                    index={i}
                    Id={item?._id}
                    tokenName={item?.contract_name}
                    imageurl={item?.logo_url}
                    status={item?.presale_status}
                    isKYC={item?.kyc}
                    isAudit={item?.audit}
                    isSAYF={item?.sayf}
                    isLiked={item?.isLiked}
                    created_date={item?.created_at}
                    start_date={timeStampToUtcDateObj(item?.start_time)}
                    end_date={timeStampToUtcDateObj(item?.end_time)}
                    fund_release_percent={
                      item.contract_first_release.toString().substring(0, 2) ||
                      "0"
                    }
                    handleLikeUnlike={handleAddRemoveFavouritePrivateSale}
                    detailPageLink={`/private-sale/${item?._id}`}
                    currency={item?.presale_currency}
                    progresspercentage={item?.progresspercentage}
                    softcap={item?.contract_softCap}
                    hardcap={item?.contract_hardCap}
                    get_total_invested_amount={item?.get_total_invested_amount}
                    contract_status={item?.contract_status}
                    forFilter={false}
                    selectedNetworkChainId={item?.selectedNetworkChainId}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>

        {/* latest presales section start */}
        <div className="latestLaunchpadHeading">
          {allTokenpageList && allTokenpageList?.length > 0 && (
            <h2 className=" font20 fontBold">Latest Token Explorer</h2>
          )}

          {allTokenpageList && allTokenpageList?.length >= 3 && (
            <Link href="/token-explorer" className="font16">
              View all
            </Link>
          )}
        </div>
        <div className="latestLaunchpadSection presalesSection">
          <Swiper
            spaceBetween={20}
            slidesPerView={3}
            onSlideChange={() => {}}
            onSwiper={(swiper) => {}}
            breakpoints={{
              220: {
                slidesPerView: 1.2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2.2,
                spaceBetween: 20,
              },
              1050: {
                slidesPerView: 2.5,
                spaceBetween: 20,
              },
              1199: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {marketPlaceData &&
              marketPlaceData.length > 0 &&
              Array.isArray(marketPlaceData) &&
              marketPlaceData.slice(0, 3).map((item, i) => (
                <SwiperSlide key={i + 1}>
                  <CartTokenPage
                    index={i}
                    Id={item?._id}
                    title="token-explorer"
                    tokenName={item?.token_data?.[0]?.name || "NA"}
                    imageurl={item?.logo_uri}
                    token_symbol={item?.token_data?.[0]?.symbol || "NA"}
                    contractAddress={
                      item?.token_data?.[0]?.contract_address || "NA"
                    }
                    network={item?.token_data?.[0]?.networkName || "NA"}
                    totalSupply={item?.token_data?.[0]?.totalSupply || "NA"}
                    tokenpageurl={`/token/${item?._id}`}
                    currency={item?.currency || "NA"}
                    isLiked={item?.isLiked}
                    handleLikeUnlike={handleTokenpageFavUnfav}
                    detailPageLink={`/launchpad/${item?._id}`}
                    onChangeTokenCartNetwork={onChangeTokenCartNetwork}
                    token_data={item?.token_data} //list
                    selected_token_data={item?.selected_token_data} //only one selected
                    token_value={item?.token_value}
                    market_value={item?.market_value}
                    liquidity_value={item?.liquidity_value}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
